import * as actionTypes from '../actions/actionTypes';
import * as _ from 'lodash';
import {generateUUID} from '../services/genId.service';
import {getEngravingAvailableInfo,getFontdefaultSize, engravingOpacity } from "../services/generateEngravings.service";

import {
  assignDeep,
  assignDeepMultiple
} from '../services/assignDeep.service'

const getUrlParam = () => {
  const urlParam = new URLSearchParams(window.location.search).get("url");
  return encodeURIComponent(urlParam);
}

const initialState = {
  loading: true,
  loadAccessories: false,
  error: null, // Si une erreur sur une création ou maj est intervenue
  current: null, // La configuration complète courante
  previousConfig:[],
  nextConfig:[],
  undoCounter:0,
  needFiltering: false,
  lastSelection: null,
  simulatePaymentSchedule: null,
  configIntro: null,
  backUrl: getUrlParam(),
};
const MAX_HISTORY = 2;

const emptyFrame = () => ({
  depth: 0,
  details: '',
  frontDepth: 0,
  granite: '',
  heights: [],
  kind: '',
  layout: 'parpaing',
  mode: '',
  price: {
    buying: 0,
    selling: 0,
    custom: null,
  },
  reference: '',
  weight: '',
  width: 0,
});

export default function (state = initialState, {type, payload}) {
  switch (type) {
    case actionTypes.CONFIGURATION_GET_START:
    case actionTypes.CONFIGURATION_INIT_START:
      return Object.assign({}, state, {
        loading: true,
        current: null,
        error: null
      });

    case actionTypes.CONFIGURATION_GET_FAILURE:
    case actionTypes.CONFIGURATION_INIT_FAILURE:
      return Object.assign({}, state, {
        error: payload.error,
        loading: false
      });

    case actionTypes.CONFIGURATION_NEED_LAYOUT_FILTERING:
    return Object.assign({}, state, {
      needFiltering: payload
    });
    
    case actionTypes.CONFIGURATION_INIT_SUCCESS:
      return state;
      // return Object.assign({}, state, {
      //   loading: false
      // });

    case actionTypes.CONFIGURATION_GET_SUCCESS:
      // if (!state.loading) {
      //   // Cas tordu où l'on appelerai le stop avant la fin de l'appel au success
      //   return state;
      // }
      return Object.assign({}, state, {
        current: payload.configuration,
        // accessories: payload.accessories,
        loading: false,
      });

    case actionTypes.ADJUST_NAME_MONUMENT:
      return assignDeepMultiple(state, {
        'current.configuration.monument.name': payload.name,
        'current.configuration.monument.alternativeMonumentName': payload.alternativeMonumentName || "",
        'loading': false,
      });

    case actionTypes.CONFIGURATION_REVERT_CONFIG:
      return Object.assign({}, state, {current: payload,loading: false,});

    case  actionTypes.CONFIGURATION_GET_ACCESSORIES_START:
      return Object.assign({}, state, {
        loadAccessories: true,
        productFrom: payload.from
      });

    case  actionTypes.CONFIGURATION_GET_ACCESSORIES_SUCCESS:
      return Object.assign({}, state, {
        accessories: payload,
        loadAccessories: false,
      });      

    case  actionTypes.CONFIGURATION_GET_SIMULATE_PAYMENT_SCHEDULE:
      return assignDeep(state, 'simulatePaymentSchedule', payload);

    case  actionTypes.CONFIGURATION_GET_INTRO:
      return assignDeep(state, 'configIntro', payload);

    case  actionTypes.CONFIGURATION_ADD_READ_ONBOARDING:
      return {
        ...state,
        configIntro: state.configIntro.map((item) => {
          if (item.id === payload.onboarding_id) {
            return {
              ...item,
              views: item.views + 1
            };
          }
          return item;
        })
      };

    case actionTypes.CONFIGURATION_STOP:
      return Object.assign({}, state, {
        loading: false,
        current: null,
        accessories: null,
        error: null
      });

    case actionTypes.CONFIG_UPDATE_HEADSTONE:
      return assignDeepMultiple(state, {
        'current.configuration.monument.headstone.reference': payload,
        'current.configuration.monument.reference': state.current.configuration.monument.footstone.reference + payload,
      });

    case actionTypes.CONFIG_UPDATE_LAYOUT:      
      return assignDeepMultiple(state, Object.assign({
        'current.configuration.monument.layout': payload,
      }, _.get(state, 'current.configuration.frame.mode') === 'custom'  ? {} : (state.current.configuration.monument.category === "SEM") ? {
        'current.configuration.frame': state.current.configuration.frame
      } : {
        // Si on change de recette et qu'on n'est pas en mode custom, on enlève la semelle directement
        'current.configuration.frame': emptyFrame()
      }));

    case actionTypes.CONFIG_UPDATE_BASE_HEIGHT:
      return assignDeep(state, 'current.configuration.monument.base.height', payload);

    case actionTypes.CONFIG_UPDATE_FRAME:
      return assignDeep(state, 'current.configuration.frame', payload);

    case actionTypes.CONFIG_REMOVE_FRAME:
      // On doit vider tous les champs vu que l'objet frame est obligatoire et que le backend effectue
      // un merge
      return assignDeep(state, 'current.configuration.frame', emptyFrame());

    case actionTypes.CONFIG_UPDATE_VENEER:
      return assignDeep(state, 'current.configuration.veneer', payload);

    case actionTypes.CONFIG_UPDATE_DISPLAY_VENEER:
      return assignDeep(state, 'current.configuration.veneer.display', payload);

    case actionTypes.CONFIG_REMOVE_VENEER:
      // On ne fait pas d'assignDeepMultiple car la fonction ne fait rien quand le path se termine par
      // 'length'
      const dimensions = _.cloneDeep(state.current.configuration.veneer.dimensions);
      _.forEach(['left', 'right'], side => {
        dimensions[side].length = null;
        dimensions[side].thickness = null;
        dimensions[side].front = null;
        dimensions[side].back = null;
      });
      _.forEach(['back', 'front'], side => {
        dimensions[side].length = null;
        dimensions[side].thickness = null;
        dimensions[side].left = null;
        dimensions[side].right = null;
      });
      return assignDeepMultiple(state, {
        'current.configuration.veneer.dimensions': dimensions,
        'current.configuration.veneer.layout': '',
      });

    case actionTypes.CONFIG_UPDATE_AT_PATH:
      return assignDeep(state, 'current.configuration.' + payload.key, payload.value);
      
    case actionTypes.CONFIG_UPDATE_GRANITE_GLOBAL:
      const oldGranite =state.current.configuration.monument.graniteMain;
      const oldGraniteSecondary =state.current.configuration.monument.graniteSecondary;
      const {bicolorMonument} = state.current.configuration
      const newPattern= (state.current.configuration.patterns || []).map(pattern =>{
        if(pattern.face && pattern.face.granit == oldGranite) {
          pattern.face.granit = payload.gm
        } else if (bicolorMonument && pattern.face && pattern.face.granit == oldGraniteSecondary) {
          pattern.face.granit = payload.gs
        }
        return pattern
      })
      const newEngraving= (state.current.configuration.engravings || []).map(engraving =>{
        if(engraving.face && engraving.face.granit == oldGranite) {
          engraving.face.granit = payload.gm
        } else if (bicolorMonument && engraving.face && engraving.face.granit == oldGraniteSecondary) {
          engraving.face.granit = payload.gs
        }
        return engraving
      })
      // On bascule les accessoires en Noir Fin si le nouveau granit n'est pas
      // disponible pour l'accessoire
      const newAccessories = (state.current.configuration.accessories || []).map(accessory => {
        if (accessory.granite && accessory.granite !== oldGranite && accessory.granite !== oldGraniteSecondary) {
          // Le granit a été spécifié à la main et différent du monument, il n'est pas modifié
          return accessory;
        }

        let newAccessory = _.merge({}, accessory);
        newAccessory.granite = null; //Par défaut non renseigné pour prendre celui du monument
        if (state.current.accessories.length){
          let value = null
          if(accessory.granite === oldGranite){
            value = payload.gm
          } else if (bicolorMonument && accessory.granite === oldGraniteSecondary) {
            value = payload.gs
          }
          const foundAcc = _.find(state.current.accessories, {reference: accessory.reference});
          if (foundAcc && foundAcc.granites){ 
            const FoundAccGranite = _.find(foundAcc.granites,{reference: value});
            if (FoundAccGranite){newAccessory.granite = value; }//Par défaut non renseigné pour prendre celui du monument
          }
        }
        // Cas où on ne trouve pas le granit dans les granitspossibles on met du Noir Fin
        if (newAccessory.granite === null){newAccessory.granite = 'NF';}
        
        return newAccessory;
      });
      // Old granites
      const oldFrameGranite = _.get(state, 'current.configuration.frame.granite');
      const oldVeneerGranite = _.get(state, 'current.configuration.veneer.granite');
      // New Granites
      let newFrameGranite = oldFrameGranite
      let newVeneerGranite = oldVeneerGranite
      if (oldGranite == oldFrameGranite) {
        newFrameGranite = payload.gm
      } else if (bicolorMonument && oldGraniteSecondary == oldFrameGranite) {
        newFrameGranite = payload.gs
      }
      const frameGranite = newFrameGranite
      if (oldGranite == oldVeneerGranite) {
        newVeneerGranite = payload.gm
      } else if (bicolorMonument && oldGraniteSecondary == oldVeneerGranite) {
        newVeneerGranite = payload.gs
      }
      const veneerGranite = newVeneerGranite
      let newGraniteSecondary = oldGraniteSecondary
      if (bicolorMonument) {
        newGraniteSecondary = payload.gs
      }
      
      return assignDeepMultiple(state, {
        'current.configuration.monument.graniteMain': payload.gm,
        'current.configuration.monument.graniteSecondary': newGraniteSecondary,
        'current.configuration.frame.granite': frameGranite,
        'current.configuration.veneer.granite': veneerGranite,
        'current.configuration.accessories': newAccessories,
        'current.configuration.patterns': newPattern,
        'current.configuration.engravings' :  newEngraving
      });

    case actionTypes.CONFIG_UPDATE_GRANITE_MONUMENT:
      return assignDeep(state, 'current.configuration.monument.graniteMain', payload);

    case actionTypes.CONFIG_UPDATE_GRANITE_FRAME:
      return assignDeep(state, 'current.configuration.frame.granite', payload);

    case actionTypes.CONFIG_UPDATE_GRANITE_VENEER:
      return assignDeep(state, 'current.configuration.veneer.granite', payload);

    case actionTypes.CONFIG_UPDATE_PATTERN_ADD:
      return addPattern(state, payload);

    case actionTypes.CONFIG_UPDATE_PATTERN_REMOVE:
      return assignDeep(state, 'current.configuration.patterns',
        _.reject(state.current.configuration.patterns, pattern => payload.indexOf(pattern.id) >= 0));

    case actionTypes.CONFIG_UPDATE_PATTERN_REMOVE_BY_PIECE:
      return assignDeep(state, 'current.configuration.patterns',
        _.reject(state.current.configuration.patterns, pattern => pattern.face.index == payload ));
    
    case actionTypes.CONFIG_UPDATE_PATTERN_REMOVE_ALL:
      return assignDeep(state, 'current.configuration.patterns', []);

    case actionTypes.CONFIG_UPDATE_PATTERN_UPDATE_PATTERN:
      return changePattern(state, payload);

    case actionTypes.CONFIG_UPDATE_PATTERN_DUPLICATE:
      return duplicatePattern(state, payload);

    case actionTypes.CONFIG_UPDATE_ENGRAVING_ADD:
      return addEngraving(state, payload);

    case actionTypes.CONFIG_UPDATE_ENGRAVING_REMOVE_ALL:
      return assignDeep(state, 'current.configuration.engravings', []);

    case actionTypes.CONFIG_UPDATE_ENGRAVING_REMOVE:
      return assignDeep(state, 'current.configuration.engravings',
        _.reject(state.current.configuration.engravings,
          engraving => payload.indexOf(engraving.id) >= 0));

    case actionTypes.CONFIG_UPDATE_ENGRAVING_REMOVE_BY_PIECE:
      return assignDeep(state, 'current.configuration.engravings',
        _.reject(state.current.configuration.engravings,
          engraving =>  engraving.face.index == payload ));
            
    case actionTypes.CONFIG_UPDATE_RESERVATION_REMOVE:
      return assignDeep(state, 'current.configuration.engravings',
      _.reject(state.current.configuration.engravings,
        engraving => engraving.reservation));

    case actionTypes.CONFIG_UPDATE_ENGRAVING_DUPLICATE:
      return duplicateEngraving(state, payload);

    case actionTypes.CONFIG_UPDATE_ENGRAVING_NEW_LINE:
      return addNewLineForEngraving(state, payload);

    case actionTypes.CONFIG_UPDATE_ACCESSORY_ADD:
      return addAccessory(state, payload);

    case actionTypes.CONFIG_UPDATE_ACCESSORY_REMOVE:
      return assignDeep(state, 'current.configuration.accessories',
        _.reject(state.current.configuration.accessories,
          accessory => payload.indexOf(accessory.id) >= 0));

    case actionTypes.CONFIG_UPDATE_ACCESSORY_REMOVE_ALL:
      return assignDeep(state, 'current.configuration.accessories', []);

    case actionTypes.CONFIG_UPDATE_ACCESSORY_DUPLICATE:
      return duplicateAccessory(state, payload);

    case actionTypes.CONFIG_UPDATE_FABRIC_ELEMENTS:
      return updateFabricElements(state, payload);

    case actionTypes.CONFIG_REMOVE_ITEM:
      const removeTargetKey = 'current.configuration.' + payload.key;
      return assignDeep(state, removeTargetKey,
        removeByIndex(_.get(state, removeTargetKey), payload.index));

    case actionTypes.CONFIG_PUSH_ITEM:
      const pushTargetKey = 'current.configuration.' + payload.key;
      return assignDeep(state, pushTargetKey,
        (_.get(state, pushTargetKey) || []).concat([payload.item]));

    case actionTypes.CONFIG_UPDATE_ITEM:
      return assignDeep(state, 'current.configuration.' + payload.key, payload.value);

    case actionTypes.CONFIG_UPDATE_FLOWER_DISPLAY:
      return assignDeep(state, 'current.configuration.hideFlowers' , payload);
 
    case actionTypes.CONFIG_UPDATE_SUCCESS:
      if (state.current) {
        return Object.assign({}, state, {
          current: payload
        });
      } else {
        // On a quitté le configurateur et on est revenu sur la page catalogue.
        return state;
      }
    case actionTypes.CONFIG_UPDATE_ALL_CONFIG:
      return{
        ...state,
        current: {...state.current,configuration:payload}
      }
        
    case actionTypes.CONFIG_LAST_SELECTION:
      return{
        ...state,
        lastSelection: payload
      }

    case actionTypes.CONFIG_ADD_HISTORY:
      //On ne garde qu'un nombre limité d'hsitorique pour éviter la surconsommation de mémoire
      if (state.previousConfig.length === MAX_HISTORY){
        state.previousConfig.shift();
      }       
      if (state.nextConfig.length === MAX_HISTORY){
        state.nextConfig.shift();
      }                 
      return{
        ...state,
        previousConfig: [...state.previousConfig, state.current.configuration]
      }
    case actionTypes.CONFIG_UNDO:
      if (state.previousConfig.length)
      {
        const previous = _.cloneDeep(state.previousConfig[state.previousConfig.length-1]);
        return{
        ...state,
        previousConfig:state.previousConfig.slice(0,state.previousConfig.length-1),
        current: {...state.current,configuration:previous},
        nextConfig:[...state.nextConfig,state.current.configuration],
        undoCounter:state.undoCounter+1
        } 
      } 
      return state;    
    case actionTypes.CONFIG_REDO:
      if (state.nextConfig.length)
      {
        const next = _.cloneDeep(state.nextConfig[state.nextConfig.length-1]);
        return{
        ...state,
        previousConfig:[...state.previousConfig, state.current.configuration],
        current: {...state.current,configuration:next},
        nextConfig:state.nextConfig.slice(0,state.nextConfig.length-1),
        undoCounter:state.undoCounter-1
        }     
      }  
      return state; 
    case actionTypes.CONFIGURATION_HAD_STATUS:
      return{
        ...state,
        current: {
          ...state.current,
          blocked : true
        }
      }
      default:
          return state; 

  }
}

/**
 * @param source Array dont on veut retirer un item
 * @param index Index de l'item à retirer
 * @returns array Source sans l'item à l'index spécifié.
 *
 * Ne mute pas l'array source.
 */
function removeByIndex(source, index) {
  return source.slice(0, index).concat(source.slice(index + 1));
}

/**
 * Remplace un élément dans une array si les 2 éléments ont la même propriété
 */
function replaceBy(source, item, property) {
  return _.map(source, _item => {
    if (_item[property] === item[property]) {
      return item;
    } else {
      return _item;
    }
  })
}

export function getDefaultPattern(face, pattern, scaleRatioPattern){  
  let objPattern = {
    id: generateUUID(),
    reference: pattern.reference,
    imprint: pattern.imprint?pattern.imprint:false,
    face: {
      index:face.piece.index,
      piece: face.piece.reference,
      profile: face.profile.reference,
      granit: face.piece.granitCode
    }
  }

  if(scaleRatioPattern && "scaleRatio" in scaleRatioPattern) {
    objPattern = Object.assign(objPattern, {
      position: {
        fabricJS: {
          x: 0.5,
          y: 0.5,
        },
        '2d': {
          x: 0.5,
          y: 0.5,
        },
        size: {
          width: pattern.size.width / face.zone.size.width * face.zone.scale,
          height: pattern.size.height / face.zone.size.height * face.zone.scale,
        },
        rotation: 0,
        scale : {
          x: scaleRatioPattern.scale.x,
          y: scaleRatioPattern.scale.y
        },
        scaleRatio : {
          x: scaleRatioPattern.scaleRatio.x,
          y: scaleRatioPattern.scaleRatio.y,
        }
      }
    });
  } else {
    objPattern = Object.assign(objPattern, {
      position: {
        fabricJS: {
          x: 0.5,
          y: 0.5,
        },
        '2d': {
          x: 0.5,
          y: 0.5,
        },
        size: {
          width: pattern.size.width / face.zone.size.width * face.zone.scale,
          height: pattern.size.height / face.zone.size.height * face.zone.scale,
        },
        rotation: 0,
        scale : {
          x: 1,
          y: 1
        }
      }
    });
  }

  return objPattern
}
  
function addPattern(state, {face, pattern}) {
  const patternConfig = getDefaultPattern(face, pattern, getScaleRatioPattern(state, pattern.reference));
  return assignDeepMultiple(state, {
    'current.configuration.patterns': state.current.configuration.patterns.concat(patternConfig),
    // On concatène sans vérifier l'unicité, on s'en fiche car c'est juste temporaire le temps que le
    // serveur retourne la vrai configuration
    'current.patterns': state.current.patterns && state.current.patterns.concat([pattern]),
  });
}

/**
 * si on a déja une config d'un "motif" existante avec "scaleRatio", il faut la recuperer pour le nouveau "motif" de la meme reference
 */
export function getScaleRatioPattern(state, reference) {
  if(state.current.configuration.patterns.length > 0) {
    const {position} = state.current.configuration.patterns.find((pattern) => reference === pattern.reference) || false;
    if (position) {
      if("scaleRatio" in position) {
        return {
          scaleRatio: position.scaleRatio,
          scale: position.scale
        }
      } else return false;
    } else return false;
  }
}

function changePattern(state, {patternId, pattern}) {
  const oldPattern = _.find(state.current.configuration.patterns, {id: patternId});
  return assignDeepMultiple(state, {
    'current.configuration.patterns': replaceBy(state.current.configuration.patterns, Object.assign({}, oldPattern, {
      reference: pattern.reference,
    }), 'id'),
    // On concatène sans vérifier l'unicité, on s'en fiche car c'est juste temporaire le temps que le
    // serveur retourne la vrai configuration
    'current.patterns': state.current.patterns.concat([pattern]),
  });
}

function duplicatePattern(state, patternId) {
  const pattern = _.find(state.current.configuration.patterns, {id: patternId});
  if (!pattern) return state;

  const newPattern = _.cloneDeep(pattern);
  newPattern.id = generateUUID();
  delete newPattern.price;
  newPattern.position.fabricJS.x += 0.05;
  newPattern.position.fabricJS.y += 0.05;
  newPattern.position['2d'].x += 0.05;
  newPattern.position['2d'].y += 0.05;
  return assignDeep(state, 'current.configuration.patterns', state.current.configuration.patterns.concat(newPattern));
}
export function getDefaultEngraving(face,engravingDefaults,reservation,user,text) {
  const opacity = engravingOpacity(user)
  return {
    id: generateUUID(),
    reservation,
    face: {
      index:face.piece.index,
      piece: face.piece.reference,
      profile: face.profile.reference,
      granit: face.piece.granitCode
    },
    text:reservation ? 'Ligne réservée' : text ? text: 'Entrez votre gravure',
    font: {
      align: 'left',
      kind: engravingDefaults.selectedType,
      family:engravingDefaults.selectedFont.id,
      color: engravingDefaults.selectedColor.id,
      size: getFontdefaultSize(engravingDefaults.selectedFont.id,user),
      lineHeight: 5,
      opacity: reservation ? opacity : false
    },
    position: {
      fabricJS: {
        x: face.piece.name == "Accessoire" ? 0.9 : 0.5,
        y: 0.5,
      },
      // On ne peut pas deviner la position à cet endroit, on va le faire dans Fabric
      // size: {
      //   width: 1,
      //   height: 1,
      // },
      scale: {
        x: 1,
        y: 1,
      },
      rotation: 0,
    },
    lines: [
      {
        text: reservation ? 'Ligne réservée' : 'Entrez votre gravure',
        position: {
          '2d': {
            x: 0.5,
            y: 0.5,
          },
          // On ne peut pas deviner la position à cet endroit, on va le faire dans Fabric
          // size: {
          //   width: 1,
          //   height: 1,
          // },
          scale: {
            x: 1,
            y: 1,
          },
          rotation: 0,
        },
      }
    ],
  };
}

function addEngraving(state, {face, reservation,text,user}) {
  const opacity = engravingOpacity(user)
  const engravingDefaults = getEngravingAvailableInfo(user,state.current.options.engravingTypes,false);

  if (state.current.options.engravingTypes.length === 0) return state;
  if (!engravingDefaults.selectedFont || !engravingDefaults.selectedColor) return state;
  const engraving = getDefaultEngraving(face,engravingDefaults,reservation,user,text);
  engraving.font.policy = reservation ?  opacity : false
  
  return assignDeep(state, 'current.configuration.engravings', state.current.configuration.engravings.concat(engraving));
}

function duplicateEngraving(state, {engravingId, x, y}) {
  const newEngraving = cloneEngraving(state, engravingId);
  if (!newEngraving) return state;

  const deltaX = x - newEngraving.position.fabricJS.x;
  const deltaY = y - newEngraving.position.fabricJS.y;
  newEngraving.position.fabricJS.x = x;
  newEngraving.position.fabricJS.y = y;

  _.forEach(newEngraving.lines, line => {
    line.position['2d'].x += deltaX;
    line.position['2d'].y += deltaY;
  });

  return assignDeep(state, 'current.configuration.engravings', state.current.configuration.engravings.concat(newEngraving));
}

function addNewLineForEngraving(state, {engravingId, text, position, lines}) {
  const newEngraving = cloneEngraving(state, engravingId);
  if (!newEngraving) return state;

  newEngraving.text = text;
  newEngraving.position = position;
  newEngraving.lines = lines;

  return assignDeep(state, 'current.configuration.engravings', state.current.configuration.engravings.concat(newEngraving));
}

function cloneEngraving(state, engravingId) {
  const engraving = _.find(state.current.configuration.engravings, {id: engravingId});
  if (!engraving) return null;

  const newEngraving = _.cloneDeep(engraving);
  newEngraving.id = generateUUID();
  delete newEngraving.price;

  return newEngraving;
}
export function getDefaultAccessory(accessory, granite, nb=1,categ='', index=null){
  let valX = 0.20;
  let valY = 0.20;
  let valAdded = accessory.kind==='Vase' ? 0.10 : 0.13;
  if (categ==='ACC'){
    nb++;
    let i = (nb-1)/4;
    if (Number.isInteger(i)){
      valY = 0.20 + (0.20*i);
      valX = 0.20;
    }else{
      let intPart = Math.floor(i);
      valY = 0.20 + (0.20*intPart);
      if ((intPart+0.25) === i){
        valX = 0.20 + valAdded;
      }else if ((intPart+0.5) === i){
        valX = 0.20 + valAdded*2;
      }else if ((intPart + 0.75) === i){
        valX = 0.20 + valAdded*3;
      }
    }
  }
  const _id =generateUUID();
  return({
    index: _id,
    id: _id,
    reference: accessory.reference,
    granite,
    details: accessory.details,
    position: {
      fabricJS: {
        x: valX,
        y: valY,
      },
      '2d': {
        x: valX,
        y: valY,
      },
      rotation: 0,
    },
  })
}

function addAccessory(state, {accessory, granite}) {
  const categ = state.current.configuration.monument.category
  const nbAccessories = state.current.configuration.accessories.length
  const nbAccessoriesByRef = state.current.configuration.accessories.map(accessorie=>accessorie.reference).reduce((cnt, cur) => (cnt[cur] = cnt[cur] + 1 || 1, cnt), {})
  const newAccessory = getDefaultAccessory(accessory, granite,nbAccessories,categ,nbAccessoriesByRef[accessory.reference] || 0);
  //Si present dans la liste standard et non déjà ajouté aux infos accessoires courants
  if (state.accessories) { 
    const foundAcc = state.accessories.length && !state.current.accessories.find(acc=>acc.reference === accessory.reference) 
                    && state.accessories.find(acc=>acc.reference === accessory.reference);
    if (foundAcc){state.current.accessories.push(_.cloneDeep(foundAcc))};
  }
  return assignDeep(state, 'current.configuration.accessories', state.current.configuration.accessories.concat(newAccessory));
}

function duplicateAccessory(state, {accessoryId, x, y}) {
  const accessory = _.find(state.current.configuration.accessories, {id: accessoryId});
  if (!accessory) return state;

  const newAccessory = _.cloneDeep(accessory);
  const _id =generateUUID();
  newAccessory.id = _id;
  newAccessory.index = _id;
  delete newAccessory.price;
  newAccessory.position.fabricJS.x = x;
  newAccessory.position.fabricJS.y = y;
  newAccessory.position['2d'].x = x;
  newAccessory.position['2d'].y = y;
  return assignDeep(state, 'current.configuration.accessories', state.current.configuration.accessories.concat(newAccessory));
}

/**
 * Cette fonction permet d'écraser les motifs/gravures/accessoires par les objets fournis en entrée.
 * Pour chaque clé et chaque objet, la fonction écrase dans la configuration l'objet ayant le même id.
 * 'elements' ressemble à :
 * <pre>
 *  {
 *    engravings: [{
 *      id: '1234567890',
 *      text: 'Mon texte',
 *      lines: [...],
 *    }]
 *  }
 * </pre>
 * @param state État courant du store
 * @param elements Un objet pouvant contenir 3 propriétés (patterns, engravings et/ou accessories) dont
 *   leur valeur est une liste d'éléments à écraser dans la configuration
 * @returns {*} Le nouvel état du store
 */
function updateFabricElements(state, elements) {
  const assignments = {};

  _.forEach(elements, (list, key) => {
    if (!list.length) return;

    const byId = _.keyBy(list, 'id');
    assignments['current.configuration.' + key] = _.map(state.current.configuration[key], element => {
      const updatedElement = byId[element.id];
      if (updatedElement) {
        return updatedElement;
      } else {
        return element;
      }
    });
  });

  if (_.size(assignments)) {
    return assignDeepMultiple(state, assignments);
  } else {
    return state;
  }
}

