import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {LazyImage} from '../../../components/LazyImage';

/**
 * Composant affichant un motif
 */
export class PatternCard extends Component {

  static propTypes = {
    pattern: PropTypes.shape({
      reference: PropTypes.string.isRequired
    }).isRequired,
    onSelectPattern: PropTypes.func.isRequired,
  };

  render() {
    const {pattern, onSelectPattern} = this.props;
    return (
      <div className="PatternCard" onClick={onSelectPattern}
         title={`${pattern.name} : ${pattern.description}`}>
        <LazyImage lazy={false} src={pattern.images.drawing} noCdn={true} />
      </div>
    )
  }
}
