import React, { Component } from "react";

import { connect } from "react-redux";
import history from "../../history";
import { bindActionCreators } from "redux";
import * as updateConfigurationActions from '../../actions/updateConfigurationActions';
import * as configurationActions from '../../actions/configurationActions';
import * as adminConfigActions from "../../actions/adminConfigActions"
import {GlobalGranitePicker} from "../configurator/granite/GlobalGranitePicker";
import {Fheader} from "./Fheader";
import {Ffooter} from "./Ffooter";
import {FgraniteColors} from './FgraniteColors';
import {BaseHeightOptionPanel} from '../configurator/components/BaseHeightOptionPanel';
import * as apiService from "../../services/api.service";
import LoadConfig from '../../components/LoadConfig';
import { getDesignTypeConfiguration } from '../../services/utils.service';
import { gtmFamily } from '../../services/gtmFamily';

class FgraniteComponent extends Component {

  state = {
    currentFilter:null,
    selected:false
  };

  async componentDidMount() {
    const { configId, clientId } = this.props.match.params;
    /*
    const { family } = this.props.user;
    const familyConfig = await apiService.get(`/api/familyconfigdef/${family}`);

    // initialiser les couleurs par défaut
    if(familyConfig && familyConfig.config) {
      let {mainColor, fontColor} = familyConfig.config
      if (mainColor === "" || !mainColor) { familyConfig.config.mainColor = "#FF9D1E" }
      if (fontColor === "" || !fontColor) { familyConfig.config.fontColor = "#001F2A" }
    }

    this.props.adminConfigActions.initConfig(familyConfig);
    */
    (!this.props.configurator.current || (configId !== this.props.configurator.current.configuration.reference  ))
        &&  this.props.actions.getConfiguration(configId);
  }

  getGraniteColours(){

  }
  filterColor(color){
    this.setState({currentFilter:color});
  }
  goToEngraving(granite){
    this.props.updateActions.updateGraniteGlobal(granite);
    const { configurator } = this.props;
    const {clientId}  = this.props.match.params;
    const configId = configurator.current && configurator.current.configuration ? configurator.current.configuration.reference :null;
    const endpoint = configurator.current.configuration.designType === "B" ? 'customize' : 'fgravure';
    history.push(`/famille/${clientId}/${configId}/${endpoint}`);
  }
  onSelectGranite (granite){
    // GTM
    const patternGranit = {
      reference: granite.reference,
      name: granite.name,
      price: granite.price,
      origin: granite.origin,
      color: granite.colorGranite
    }
    gtmFamily().customizeConfigurator(
      "configurator.customize",
      "liste granits",
      {
        type: "granit",
        query: "granit",
        filter: granite.name
      },
      false,
      patternGranit
    )
    // end GTM
    this.setState({selected:granite.reference},
      () => {
        if (this.props.configurator.current.configuration.designType === "B")
        this.goToEngraving(this.state.selected)
      });
  }
  closeModal(){
    this.setState({selected:false});
  }
  getHoverInfo(granite){
    return({fct1:elt=>this.goToEngraving(granite),fct2:(elt)=>this.onSelectGranite(granite),
      label1:"Choisir ce granit", label2:"Voir ce granit en grand"})
  }
  goToCatalog(){
    const {clientId}  = this.props.match.params;
    history.push(`/famille/${clientId}`);
  }

  render() {
    const {currentFilter,selected} = this.state;
    const current = this.props.configurator.current;
    const config = current ? current.config : false;
    const designType = getDesignTypeConfiguration(config);
    const {mainColor} = this.props.adminConfig
    return (
     <div className="Fgranites">
        {designType==="A" && <Fheader match={this.props.match} /> }
        { !this.props.configurator.current && <LoadConfig big loading={this.props.configurator.loading} />}
        { this.props.configurator.current && <div>
            <FgraniteColors family={true} hideTitle={true} designType={designType} filter={(color)=>this.filterColor(color)} currentColor={currentFilter} mainColor={mainColor}/>
              <div>
                <GlobalGranitePicker noModal={true} filter={currentFilter} price={true} carousel={true} // hoverInfo={this.getHoverInfo.bind(this)}
                    onSelect ={this.onSelectGranite.bind(this)} goNext ={this.goToEngraving.bind(this)}
                    family={true} selected={selected} closeModal={this.closeModal.bind(this)}/>
                <BaseHeightOptionPanel hideDisplay={true} />{/* super important pour mettre à jour la bonne hauteur */}
              </div>

            {/* <OvalAction action={this.goToCatalog.bind(this)} text="Étape précédente" arrow="left" /> */}
            {current.configuration.designtype === "A" && <Ffooter previous={()=>this.goToCatalog()} />}
        </div>}
      </div>
    );
  }
}

export const Fgranite = connect(
  (state) => ({
    user:state.user,
    configurator: state.configurator,
    adminConfig: state.adminConfig.config,
  }),
  dispatch => ({
    actions: bindActionCreators(configurationActions, dispatch),
    updateActions: bindActionCreators(updateConfigurationActions, dispatch),
    adminConfigActions: bindActionCreators(adminConfigActions, dispatch),
  })
)(FgraniteComponent);
