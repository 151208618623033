import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import defaultImage from './../../../../src/images/defaultImage.svg';
import {LazyImage} from "../../../components/LazyImage";
import {displaySimpleAccesory} from '../../../services/image.fct.service';

/**
 * Composant affichant un granite en mode 'texture'
 */
export class AccessoryGranite extends Component {
  state = { imageUrl: ''};
  static propTypes = {
    granite: PropTypes.shape({
      reference: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    selected: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    onSelectGranite: PropTypes.func.isRequired,
    accessoryRef: PropTypes.string.isRequired
  };

  $parent = null;

  render() {
    const {selected, disabled, granite} = this.props;
    return (
      <div className={cn(['GraniteCard',
        'GranitePreviewCard','accessory-granite-card', {selected, disabled}])}>
        <a onClick={() => !selected && !disabled && this.props.onSelectGranite(granite)}
          ref={($parent)=> this.$parent = $parent}
        >
          <figure>
            <LazyImage src={displaySimpleAccesory(this.props.accessoryRef,granite.reference)} lazy={false} customStyle={true}/>
          </figure>
          {disabled && (
            <div className="DisabledMessage">
              <p>Ce granit n'est pas compatible avec celui du monument</p>
            </div>
          )}
        </a>
        <div className="Infos">
          <span className='Name'>
            {granite.name}
          </span>
        </div>
      </div>
    )
  }
}
