import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as configurationActions from "../../actions/configurationActions";
import * as catalogActions from "../../actions/catalogActions";
import * as quoteActions from "../../actions/quoteActions";
import { NotifUpdate } from "./components/NotifUpdate";
import { formatFaceToId } from "../../services/format.service";
import * as _ from "lodash";
import { Messages } from "../../components/messages";
import history from "../../history";
import { ConfiguratorMonument } from "./ConfiguratorMonument";
import { ConfiguratorGranit } from "./ConfiguratorGranit";
import { ConfiguratorMotif } from "./ConfiguratorMotif";
import { ConfiguratorAccessory } from "./ConfiguratorAccessory";
import { ConfiguratorCompletion } from "./ConfiguratorCompletion";
import {ConfiguratorSelection} from "./ConfiguratorSelection";
import {userCan} from '../../services/userRights.service';
import {isSmartphone} from '../../services/domFunctions';
import {getReactAppExtranet, checkDisplaySimpleConfig, configurationOrdered, getMonumentCategory} from '../../services/utils.service';
import {isFromStock} from '../../services/restrictedConfiguration';
import * as updateConfigurationActions from '../../actions/updateConfigurationActions';
import * as apiService from "../../services/api.service";
import { Route, Switch } from "react-router";
import { TranslationContext } from "../../context/TranslationContext";
import {getStore} from '../../store';
import { Modal } from '../../components/Modal'

export const navBarId = "MainNavBarID";
// Route et Navigation de l'application
let tabList = [];

class ConfiguratorComponent extends Component {
  static contextType = TranslationContext;
  static propTypes = {
    actions: PropTypes.shape({
      getConfiguration: PropTypes.func.isRequired
    }),
    configurator: PropTypes.shape({
      current: PropTypes.shape({
        configuration: PropTypes.object.isRequired
      })
    }),
    children: PropTypes.any
  };

  state = {
    width: window.innerWidth,
    backToAdminBtn: true,
    initialRedirection:false,
    quote:false,
    selectedComponent: null,
    modalVisible: false,
  };

  checkboxRef = React.createRef();
  
  async componentDidMount() {
    const { params } = this.props.match;
    const {configurator} = this.props;
    const familyUser = getStore().getState().user
    const member_id = familyUser && familyUser.id ? familyUser.id : null
    member_id && this.props.actions.getIntroJson(member_id);
    
   if (  !configurator.current){
    // On vient d'afficher la page et la configuration n'existe pas dans Redux
    if (this.props.match.url.includes("quote_variant")){
      this.props.quoteActions.initConfigWithQuote(params.configId);
      this.setState({quote:true})
    } else {
      this.props.actions.getConfiguration(params.configId);
    }
   }
    
    window.addEventListener('resize', this.handleWindowSizeChange);

    // 
    if (window.location.href.includes("adminfamilyNewPreconfig")) {
      this.setState({backToAdminBtn: false});
    }

    if (!this.props.configurator.accessories){
      const from = new URLSearchParams(window.location.search).get("from")
      this.props.actions.startGettingAccessories(from);
      const accessories = await apiService.get('/api/catalog/accessories',  from && {from});
      this.props.actions.storeAccessories(accessories);
    }
  }

  componentWillUnmount() {
    this.props.actions.stopConfiguration();
    window.addEventListener('resize', this.handleWindowSizeChange);
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  goToMonument = () => { 
    const {reference} = this.props.configurator.current.configuration.monument;
    history.replace(`/monument/${reference}`, {
      backUrl: this.props.configurator.backUrl
    });
  }
  stockRedirection = (configId) => {
    // SI c'est un accessoire de stock va initialement dans l'onglet accessoires
    const monumentCategory = _.get(this.props.configurator, "current.configuration.monument.category", "");
    if (monumentCategory === "ACC") {
      this.setState({initialRedirection:true});
      history.replace(`/configuration/${configId}/monument`, {
        backUrl: this.props.configurator.backUrl
      });
      return 0;
    }
        // SI c'est un monument de stock on va initialement dans l'onglet Motifs/gravures avec la vue par défaut
        const theDefaultFace = _.find(this.props.configurator.current.options.faces, "default");
        if (theDefaultFace){
          this.setState({initialRedirection:true,motifOpts: {face: theDefaultFace,drawing: false,defaultFace: true}});
          history.replace(`/configuration/${configId}/motifs-gravures/${formatFaceToId(theDefaultFace, null)}`, {
            backUrl: this.props.configurator.backUrl
          });
        }else{
          this.setState({initialRedirection:true});
          history.replace(`/configuration/${configId}/accessoires`, {
            backUrl: this.props.configurator.backUrl
          });
        }
  }
  quoteRedirection = (configId) =>{
    this.setState({initialRedirection:true});
    history.replace(`/configuration/${configId}/granit`, {
      backUrl: this.props.configurator.backUrl
    });
  }

  componentDidUpdate(prevProps) {

    const prevParams = prevProps.match.params;
    const nextParams = this.props.match.params;
    if (this.props.configurator.current ){
      if (!this.state.initialRedirection && isFromStock(this.props.configurator.current.configuration)){
        // Gestion d'un monument venant de stock
        this.stockRedirection(nextParams.configId);
      }else if (!this.state.initialRedirection && this.state.quote){
        // Gestion d'un devis
        this.quoteRedirection(nextParams.configId);
      }
      else{
      //Variante depuis "Mes Configurations" on va dans l'écran finalisation
      if (this.props.location.pathname.includes("variant")){
        history.replace(`/configuration/${nextParams.configId}/finalisation`, {
          backUrl: this.props.configurator.backUrl
        });
      }
      if (this.props.match.isExact){
        // Quand on vient de l'extranet il n'y a pas de /monument donc on redirige vers le monument
        history.replace(`/configuration/${nextParams.configId}/monument`, {
          backUrl: this.props.configurator.backUrl
        });
      }
      if (prevParams.configId !== nextParams.configId) {
        // Afin de gérer la création de variante depuis l'écran de finalisation
        setTimeout(() => {
          this.props.actions.stopConfiguration();
          this.props.actions.getConfiguration(nextParams.configId);
        });
      } else if (this.props.configurator.current) {
        // L'écran Motif / Gravures nécessite une face. On prend celle spécifié dans l'url ou celle par
        // défaut pour construire les liens.
        const faces = this.props.configurator.current.options.faces;
        const currentLocation = this.props.location.pathname;
        const previousLocation = prevProps.location.pathname;
        let theFace = null;
        if (currentLocation.includes("motifs-gravures/")) {
          theFace = currentLocation.substr(currentLocation.lastIndexOf("/") + 1);
        } 
          if (theFace && previousLocation!== currentLocation ) {
            // On vient d'afficher la page motif sur une face spécifiée. On met à jour l'état du configurateur pour stocker cette face.
            const drawing = theFace.split("_").length === 3;
            const face = _.find(faces, face => formatFaceToId(face, drawing) === theFace);
            if (face) {
              // La face demandée existe
              this.setState({motifOpts: {face,drawing,defaultFace: true}});
            } else {
              // La face demandée n'existe pas. On redirige vers celle par défaut.
              this.setState(
                {motifOpts: null},() => {const defaultFace = _.find(faces, "default");
                  history.replace(`/configuration/${nextParams.configId}/motifs-gravures/${formatFaceToId(defaultFace, drawing)}`, {
                    backUrl: this.props.configurator.backUrl
                  });
                }
              );
            }
          } else if (!this.state.motifOpts ) {
            // On positionne la face par défaut.
            this.setState({motifOpts: {face: null,drawing: false,defaultFace: true}});
        } else {
          // changement de stèle? reset face par défaut, attention: `faces` n'est pas encore à jour!
          const previousHeadstone = prevProps.configurator.current && prevProps.configurator.current.configuration.monument.headstone;
          const currentHeadstone = this.props.configurator.current && this.props.configurator.current.configuration.monument.headstone;

          if ((previousHeadstone && previousHeadstone.reference) !== (currentHeadstone && currentHeadstone.reference)) {
            this.setState({ motifOpts: { face: null, drawing: false, defaultFace: true } });
          }else if(this.state.motifOpts && this.state.motifOpts.face && this.state.motifOpts.face.piece && this.state.motifOpts.face.piece.name == "Accessoire"){
            let newFace = this.state.motifOpts.face
            const face = faces.filter(f => f.piece.reference == newFace.piece.reference && f.piece.index == newFace.piece.index)
            if(face[0] && face[0].piece.granitCode != newFace.piece.granitCode){

              newFace.piece.granitCode=face[0].piece.granitCode;
              this.setState({ motifOpts: { face: newFace ,drawing: false, defaultFace: true } }); 
            }else if (!face[0]){
              this.setState({ motifOpts: { face: null, drawing: false, defaultFace: true } });
            }
          }

        }

      }
    }
  }
  }
  previousNextLinks = (index,defaultFaceId,configId) =>{
    let nextIndex = index;
    let previousIndex = index;
    let previousLink = false;
    let nextLink= false;
    while (previousIndex > 1 && !previousLink){
      if (this.checkDisplay(tabList[previousIndex-1]) && tabList[previousIndex-1].labelFooter){
        previousLink = {link:this.getItemLink(tabList[previousIndex-1],configId,defaultFaceId), label:tabList[previousIndex-1].labelFooter}
      }else{previousIndex -=1;}
    }
    while (nextIndex>0 && nextIndex<tabList.length - 1 && !nextLink){
      if (this.checkDisplay(tabList[nextIndex + 1]) && tabList[nextIndex+1].labelFooter){
        nextLink = {link:this.getItemLink(tabList[nextIndex+1],configId,defaultFaceId), label:tabList[nextIndex+1].labelFooter}
      }else{nextIndex +=1;}      
    }
    return {previousLink,nextLink}
}
  getItemLink = (item, configId, defaultFaceId)=>{
    const defaultLink = item.endpath ? `/configuration/${configId}/${item.endpath}`:'/';
    const link = item.config ==="patterns" && defaultFaceId ? defaultLink+`/${defaultFaceId}`: defaultLink;
    return link
  }

  myComponent = (component, motifOpts, defaultFaceId,config,configId) => {
    const index = tabList.findIndex(item=>item.config === config);
    const footerLinks = this.previousNextLinks(index,defaultFaceId,configId);
    const TheComponent = component;
    return <TheComponent defaultFaceId={defaultFaceId} motifOpts={motifOpts} isSmartphone={isSmartphone()} footerLinks={footerLinks}/>;
  };

  test= (param)=>{
    switch (param){
      case "configAdmin":
        return userCan.configAdmin() && !this.state.quote ;
      case "stock":
        return !(this.props.configurator.current && isFromStock(this.props.configurator.current.configuration))
      case "showMnt":
      case "showCatalog":
        return !this.state.quote
      default:
        return false     
    }
  }

  checkDisplay(item){
    const { configurator } = this.props
    if ((item.test && !this.test(item.test)) && !(item.test == "stock" && configurator.current && configurator.current.configuration.monument.category === "ACC")) {return false;} // on vérifie si l'item doit être affiché
    if (isSmartphone() && item.hideSmartphone) return false;
    if (this.props.configurator.current &&  checkDisplaySimpleConfig(this.props.configurator.current.configuration) && item.hideCatalogSelection){
      return false}
    return true;
  }

  checkDisabled(){
    if (this.props.configurator.current && configurationOrdered(this.props.configurator.current.configuration)){
      return true
    }
    return false
  }

  async backToAdmin  (){
    await this.props.catalogActions.load();
    history.push("/admin/catalog");
  }

  handleClick (e, selectedComponent){
    this.setState({selectedComponent})
    this.checkboxRef.current.checked = false; // close dropDown
    if (this.checkDisabled()){
      e.preventDefault();
    }
  }

  isNavLinkActive(item) {
    const { configId } = this.props.match.params;
    return this.props.location.pathname === `/configuration/${configId}/${item.endpath}`;
  }

  processCatalogAndGraniteItems(tabList){
    if(tabList[0] && tabList[0].config === "catalog" && !this.checkDisplay(tabList[0])) {
      tabList.forEach(item => {
        if(item.config === "granite") {
          item.class = "New First"
        }
      })
    }
  }

  render() {
    // Récupérer la fonction de traduction à partir du contexte
    const t = this.context;
    const monumentCategory =this.props.configurator.current && this.props.configurator.current.configuration.monument.category
    
  
    const monumentComponents = getMonumentCategory();

    const { configId } = this.props.match.params;
    if(monumentCategory in monumentComponents){
      if (monumentCategory==="ACC"){
        const firstAcc = this.props.configurator.current && this.props.configurator.current.configuration.accessories.length > 0 && this.props.configurator.current.configuration.accessories[0].reference
        tabList = [
          { label: t("config3d_stepper_catalog"), endpath: null, icon: "catalog-icon", config: "catalog", activeClass: "New First",test:"showCatalog",
            labelFooter:"Nouvelle configuration"},
  
          { label: t("config3d_stepper_accessory"), endpath: "monument", icon: "accessory-icon", config: "monument",
             component: ConfiguratorMonument, test:"showMnt",labelFooter: t("config3d_navbuttons_pattern_accessories")},
             
          { label: t("config3d_stepper_pattern_engravings"),endpath: "motifs-gravures",icon: "patterns-icon",config: "patterns",exact:false,
              component: ConfiguratorMotif,hideSmartphone: false,labelFooter: t("config3d_navbuttons_pattern_engravings")},
  
          { label: t("config3d_stepper_position"),endpath: "accessoires", icon: "position-icon",config: "accessories",
             component: ConfiguratorAccessory,hideSmartphone: false,test:"stock",labelFooter:  t("config3d_navbuttons_accessories_positions")},
  
          { label: `${t("config3d_stepper_finalize_accessory")}`, endpath: "finalisation", icon: "finalization-icon", config: "finalization", class: "Last",
            component: ConfiguratorCompletion, hideSmartphone: false,hideCatalogSelection: true, labelFooter: t("config3d_navbuttons_pattern_finalize")}
        ];
        if (this.props.configurator.current.configuration.accessories.length == 0) {
          const { configurator } = this.props
          const accRef = _.get(configurator, "current.configuration.monument.accessoryRef", "")
          if ( accRef != "" && accRef != null && accRef != undefined) {
            const accessoryGranit = accRef.slice(-2)
            const accessoryRef = accRef.slice(0, -2)
            const accessoryObj = this.props.configurator.current.accessories.find(accessory => accessory.reference === accessoryRef )
            this.props.updateConfigurationActions.addAccessory(accessoryObj, accessoryGranit || "NF")
          }
        }
        updateConfigurationActions.updateProductId('monument.',firstAcc)
      }else{
        let label = "";
        let labelFooter = "";
        if (monumentCategory === "PLT") {
          label = t("config3d_stepper_veneer");
          labelFooter = t("config3d_navbuttons_veneer");
        } else if(monumentCategory === "SEM") {
          label = t("config3d_stepper_frame");
          labelFooter = t("config3d_navbuttons_frame");
        }
        tabList = [
          { label: t("config3d_stepper_catalog"), endpath: null, icon: "catalog-icon", config: "catalog", activeClass: "New First",test:"showCatalog",
            labelFooter:"Nouvelle configuration"},
  
          { label:label, endpath: "monument", icon: "monument3-icon", config: "monument", 
             component: ConfiguratorMonument, test:"showMnt",labelFooter:labelFooter},
  
          { label: t("config3d_stepper_granit"), endpath: "granit", icon: "granite-icon", config: "granite", 
            component: ConfiguratorGranit,test:"stock", labelFooter: t("config3d_navbuttons_granit")},
  
          { label: t("config3d_stepper_finalize"), endpath: "finalisation", icon: "finalization-icon", config: "finalization", class: "Last",
            component: ConfiguratorCompletion, hideSmartphone: false,hideCatalogSelection: true, labelFooter: t("config3d_navbuttons_pattern_finalize")}
        ];
      }
      
    }else if (monumentCategory !== null){
      const labelText = monumentCategory==="SEM"? `${t("config3d_stepper_finalize")} (Quantité)` : t("config3d_stepper_finalize")
      tabList = [
        { label: t("config3d_stepper_catalog"), endpath: null, icon: "catalog-icon", config: "catalog", activeClass: "New First",test:"showCatalog",
          labelFooter:"Nouvelle configuration"},
      
        { label: t("config3d_stepper_monument"), endpath: "monument", icon: "monument3-icon", config: "monument", 
           component: ConfiguratorMonument, test:"showMnt",labelFooter: t("config3d_navbuttons_monument")},
      
        { label: t("config3d_stepper_granit"), endpath: "granit", icon: "granite-icon", config: "granite", 
          component: ConfiguratorGranit,test:"stock", labelFooter: t("config3d_navbuttons_granit")},
      
        { label: t("config3d_stepper_pattern_engravings"),endpath: "motifs-gravures",icon: "patterns-icon",config: "patterns",exact:false,
          component: ConfiguratorMotif,hideSmartphone: false,labelFooter: t("config3d_navbuttons_pattern_engravings")},
      
        { label: t("config3d_stepper_accessory"),endpath: "accessoires", icon: "accessory-icon",config: "accessories",
          component: ConfiguratorAccessory,hideSmartphone: false,test:"stock",labelFooter: t("config3d_navbuttons_pattern_accessories")},
      
        { label: t("config3d_stepper_selection"), endpath: "selections",icon: "selections-icon",config: "selections",test:"configAdmin", // déclenche l'appel à this.test_selections pour vérifier si l'onglet doit être affiché
          component: ConfiguratorSelection, hideSmartphone: false,hideCatalogSelection: true},
      
        { label: labelText, endpath: "finalisation", icon: "finalization-icon", config: "finalization", class: "Last",
          component: ConfiguratorCompletion, hideSmartphone: false,hideCatalogSelection: true, labelFooter: t("config3d_navbuttons_pattern_finalize")}
      ];
    }
    const faceId =
      this.state.motifOpts &&
      this.state.motifOpts.face &&
      formatFaceToId(this.state.motifOpts.face, this.state.motifOpts.drawing);
    
    const { selectedComponent } = this.state;
    // attribuer la classe "First" au step "Granite" si le step "Catalogue" n'est pas affiché.
    this.processCatalogAndGraniteItems(tabList)

    const backUrl = this.props.configurator.backUrl || this.props.location.state.backUrl;
    return (
      <div className= "Configuration height-100">
          <input id="dropstate" type="checkbox" ref={this.checkboxRef} />
          <div className="Header">
          <label className="label-content" htmlFor="dropstate">
            {
              selectedComponent ? <span className={`${selectedComponent.icon} icon icon-large`}></span>
              : <span className={"monument3-icon icon icon-large"}></span>
            }
            {selectedComponent ? selectedComponent.label : "Monument"}
          </label>
            <nav className="Header cfg-steps" id={navBarId}>
              {backUrl && tabList.map(
                (item, index) =>{
                  if (item.config === "catalog"){
                    return(this.checkDisplay(item) && 
                     <a className={item.activeClass ? item.activeClass : ""}
                     key={index}
                     style={{cursor: "pointer"}}
                     onClick={() => this.setState({modalVisible: true})}>
                      <div className="step-content active">
                        {item.before && <span>{item.label}</span>}
                        <span className={`${item.icon} icon icon-large`}></span>
                        <span className="label">
                          {!item.before && item.label}
                        </span>
                      </div>
                    </a>
                    )
                  } else {
                    return (this.checkDisplay(item) && <NavLink
                      key={index}
                      onClick ={(e)=>this.handleClick(e, item)}
                      className={this.checkDisabled() ? "disabled" : item.class ? item.class : ""}
                      activeClassName={item.activeClass ? item.activeClass : "active"}
                      to={item.endpath ? `/configuration/${configId}/${item.endpath}` : "/"}>
                      <div
                        className={`step-content ${this.isNavLinkActive(item) ? "active" : ""}`}
                      >
                        {item.before && <span>{item.label}</span>}
                        <span className={`${item.icon} icon icon-large`}></span>
                        <span className="label" title={this.checkDisabled() ? "La commande est finalisée vous ne pouvez plus modifier ce monument":""}>
                          {!item.before && item.label}
                        </span>
                      </div>
                    </NavLink>
                  )}
                }
              )}
            </nav>
          </div>
        <div className="Content height-100">
          {this.props.configurator.loading && <p className="LoadingMessage">Chargement de la configuration en cours</p>}
          {this.props.configurator.error && <p className="Error">{this.props.configurator.error}</p>}

          {this.props.configurator.current && (
            <Switch>
              {tabList
                .filter(item => item.component && this.checkDisplay(item))
                .map(
                  (item, index) =>
                      <Route
                       key={index}
                       exact ={item.exact === false ? false:true}
                        path={`/configuration/:configId/${item.endpath}`}
                        render={() => this.myComponent(item.component, this.state.motifOpts, 
                                                      faceId,item.config, this.props.configurator.current.configuration.reference)}
                      />
                )}
            </Switch>
          )}
           {( this.state.backToAdminBtn && this.props.configurator.current && this.props.configurator.current.configuration.configType ==="catalog" ) &&
              <button className="backToAdmin" onClick={()=>this.backToAdmin()}>Retour Admin</button>}
        </div>
        <NotifUpdate />
        <Messages />

        {(!this.test("stock") && !this.props.location.pathname.includes("finalisation") && monumentCategory !== "ACC") &&
         <div className="stockMessage">Vous configurez un monument en stock.
          <div>Pour configurer une fabrication sur mesure, <span onClick={()=>this.goToMonument()}>cliquez ici</span></div></div>}

        {(!this.test("stock") && !this.props.location.pathname.includes("finalisation") && monumentCategory === "ACC") &&
        <div className="stockMessage">Vous configurez un accessoire en stock.<br/>Les modifications que vous apportez sont soumis à des réserves de disponiblité. </div>}

        {/* <Redirect from="/configuration/" to="/monument" /> */}

        <Modal
          isOpen={this.state.modalVisible}
          onRequestClose={() => this.setState({modalVisible: false})}
          className='engraving-alert'
        >
          <p>{t("config3d_stepper_leave_configurator")}</p>
          <a 
            className="text-cta secondary1 custom-secondary-button button-primary button-small text-cta btn-leave-conf d-block"
            href={decodeURIComponent(this.props.configurator.backUrl)} target="_top"
            style={{margin:"0 auto", width:"53px", textAlign: "center"}}
          >
            OK
          </a>
        </Modal>
      </div>
    );
  }
}

export const Configurator = connect(
  (state, ownProps) => ({
    configurator: state.configurator,
    catalog: state.catalog,
  }),
  dispatch => ({
    actions: bindActionCreators(configurationActions, dispatch),
    catalogActions: bindActionCreators(catalogActions, dispatch),
    quoteActions :  bindActionCreators(quoteActions, dispatch),
    updateConfigurationActions: bindActionCreators(updateConfigurationActions, dispatch),
  })
)(ConfiguratorComponent);
