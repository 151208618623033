import React from 'react';
import * as _ from 'lodash';
import {getFootstoneCode,formatMonumentName, getGraniteName, formatFrame} from '../../../services/format.service';
import { isSmartphone } from '../../../services/domFunctions';
import {displayPackage,packageInfoFamily,totalPricePackageToDisplay} from "../../../services/utils.service";
import { priceFallback } from '../../configurator/completion/CompletionLine';
import { LeftOvalAction } from '../../../components/LeftOvalAction';
import prixmacaron from '../../../images/prixmacaron.png'
import FquoteHelp from './FquoteHelp'
import ReactDOM from "react-dom";


export const prepareData = (current,user,notFamily,priceInfos ) => {
  const {configuration} = current;
  const { monument } = configuration;
  let footstoneName = `GPG ${getFootstoneCode(monument.footstone.reference)}`;
  if (monument.catalog && monument.catalog.name !== "") {
    footstoneName = monument.catalog.name
  }
  const orgInfo = priceInfos ? priceInfos : user;
  const price = notFamily ? configuration.price.custom: getPrice(configuration,orgInfo,notFamily,"--");
  let lines = getDisplayLines(current,orgInfo,notFamily);
  return ({name:footstoneName,price:price,lines:lines});
};
const getPrice =  (elt,user,notFamily, text = "Non applicable")=>{
  if (notFamily) {
    return (elt.price ? priceFallback(user,elt.price) : text)
  }else{
    return( displayPackage(user) && elt.price &&  elt.price.sellingWithoutPackage ? elt.price.sellingWithoutPackage :
  elt.price && elt.price.selling ? elt.price.selling : text)//Backward compatibility with existing confs)
  }
}

const getFrameInfo=(frame,monument,current,user,notFamily)=>{
  if (!frame.kind){return null}
  const kind = _.find(current.options.frames, {kind: frame.kind}).label;
  if(!frame || frame.kind === "" ){ return null}
  const granite = frame.granite || monument.graniteMain;
  const name =[`Semelle ${kind} ${formatFrame(frame)}`,`Granit : ${getGraniteName(granite,current.configuration,current.options)}`];
  const price = getPrice(frame,user,notFamily);
  return ({lines:name,price:price});
}
const getLayoutInfo =(current,monument)=>{
  const layout = _.find(current.options.layouts, {reference: monument.layout});
  const layoutName = layout && layout.name;
  const sbs = _.padStart(monument.base.height, 2, '0');
  return `${layoutName}×${sbs}`
}
const getMonumentInfo = (current,monument,user,notFamily)=>{
  const label=[`${formatMonumentName(monument)}`,`Recette: ${getLayoutInfo(current,monument)}`,
               `Granit : ${getGraniteName(monument.graniteMain,current.configuration,current.options)}`];
  const price = getPrice(monument,user,notFamily); 
  return ({lines:label,price:price});

}
const getAccessoriesInfo= (configuration,current,user,notFamily)=>{
  if(!configuration.accessories || configuration.accessories.length === 0 ){ return null}
  let accessories = [];
  configuration.accessories.forEach(elt=>{
    if (current){
      const price = getPrice(elt,user,notFamily);
      const itemDef =  current.accessories.find(acc=>acc.reference === elt.reference) || {kind:""};
    elt.price && accessories.push({lines:[`${itemDef.kind} ${elt.reference}`,`${itemDef.description}`,`${itemDef.details}`],price:price});
    }
  });
  return accessories;

}
const getPatternsInfo=(configuration,current,user,notFamily)=>{
  if(!configuration.patterns || configuration.patterns.length === 0 ){ return null}
  let patterns = [];
  configuration.patterns.forEach(elt=>{
    if (current){
      const itemDef =  current.patterns.find(pat=>pat.reference === elt.reference) || {kind:""};
      const price = getPrice(elt,user,notFamily);
      elt.price && patterns.push({lines:[`Motif ${itemDef.kind} ${elt.reference}`],price:price});
    }
  });
  return patterns;

}
const getEngravingInfo = (configuration)=>{
  if(!configuration.engravings || configuration.engravings.length === 0 ){ return null}
  let engravings = [];
  configuration.engravings.forEach(elt=>{
    const price = getPrice(elt);
    elt.price && engravings.push({lines:[`Gravure Lettres ${elt.font.kind}`,elt.text],price:price});
  });
  return engravings;
}  
const getPackageInfo = (configuration,user)=>{
  const price = totalPricePackageToDisplay(user,configuration);
  const displayPackage =  _.get(user,"identity.organization.packageText");
  if (!price) return null
  return ({lines:[displayPackage],price:price});
}
const additionalLines = (configuration)=>{
  if (configuration.additional && configuration.additional.lines){
    let lines = [];
    configuration.additional.lines.forEach(elt=>{
      elt.price && elt.price.custom && lines.push({lines:[elt.title],price:elt.price.custom})
    })
    return lines
  }
  return false
}

export const getDisplayLines = (current,user,notFamily)=>{
  const {configuration} = current;
  const {monument} = configuration;
  let lines =[];
  lines.push(getMonumentInfo(current,monument,user,notFamily));
  const frameInfo = getFrameInfo(configuration.frame,monument,current,user,notFamily);
  if(frameInfo){lines.push(frameInfo);}
  const engravings = getEngravingInfo(configuration);
  if (engravings){lines = lines.concat(engravings);}
  const patterns = getPatternsInfo(configuration,current,user,notFamily);
  if (patterns){lines = lines.concat(patterns);}
  const accessories = getAccessoriesInfo(configuration,current,user,notFamily);
  if (accessories){lines = lines.concat(accessories);}
  if (notFamily){

    const addLines = additionalLines(configuration);
    if(addLines) { lines = lines.concat(addLines)};
    const thePackage = getPackageInfo(configuration,user);
    if(thePackage){lines = lines.concat(thePackage);}
  }

  return lines;
}
export class FpriceList extends React.Component {
  state={
    help:false
  }
  packageInfo(){
    const {from} = this.props;
    if (this.props.current){
      const orgInfo = this.props.priceInfos ? this.props.priceInfos : this.props.user;
      return packageInfoFamily(orgInfo,this.props.current.configuration,this.props.notFamily, from);
    }
    
  }

  render () {
    const { current,user,fromSpace, fromCompare, askQuote, priceInfos, from} = this.props;
    const { help } = this.state;
    const data = prepareData(current,user,this.props.notFamily,priceInfos);
    const beforePrice = this.props.notFamily ? "" : "À partir de" ;
    const classFont = data && data.lines.length>0 ? data.lines.length >12 ? "tiny" : data.lines.length >9 ? "smaller":"":"";
    const { mainColor, fontColor } = this.props.colorConfig || {};
    if(!fromCompare){
      return (
        <div className={`prices ${classFont} ${isSmartphone() && 'mobilePrices'}`}>
          <div className="priceListContainer">
            {data && data.lines.map((line,index)=>
              <div className="priceListRow" key ={index}>
                <div className="priceListColTitle">{line.lines.map((item, index)=><p key={index} class="paragraph-min-line">{item}</p>)}</div>
                <div className="priceListColPrice">{isNaN(line.price) ? line.price: line.price +"€" }</div>
              </div>)}
          </div>
          <div className="price">{beforePrice} <span>{data && data.price}€*</span></div>
          <div className="packagePrice"> 
            {this.packageInfo()} 
            <img src={prixmacaron} className="packagePriceInfo" alt="Package Price Info"/>
          </div>
          {fromSpace && <LeftOvalAction colorConfig={mainColor && fontColor && {mainColor, fontColor}}  text="Demander un devis" addClass="fsp-button"  action={()=> askQuote()}/>}
          {from != "FbigDetailB" && <FquoteHelp from="FpriceList" />}
        </div>

      )
    }else {
      return (
        <td className="detailsItem">
          {data && data.lines.map((line,index)=>
            <div>
              <div>
                {line.lines.map((item, index)=><div key={index}>{item}</div>)}
              </div>
              <div className="detailsPrice">{isNaN(line.price) ? line.price: line.price +"€" }
              </div>
            </div>
          )}
        </td>
        /*<div className={`prices ${classFont} ${isSmartphone() && 'mobilePrices'}`}>
          <div className="priceListContainer">
            {data && data.lines.map((line,index)=>
              <div className="priceListRow" key ={index}>
                <div className="priceListColTitle">{line.lines.map((item, index)=><p key={index}>{item}</p>)}</div>
                <div className="priceListColPrice">{isNaN(line.price) ? line.price: line.price +"€" }</div>
              </div>)}
          </div>
        </div>*/
      )
    }
  }
};

